import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import * as styles from './index.module.scss'

const App = () => {
  // intersection Observer code
  const containerRef = useRef([])
  
  const observerOptions = {
    root: null,
    rootMargin: '50px',
    threshold: .85
  }

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add(styles.active)
        } else {
          entry.target.classList.remove(styles.active)
        }
    })
  }
  
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, observerOptions)
    document.querySelectorAll('.section').forEach((i) => {
      if (i) {
        observer.observe(i);
      }
    })
  }, [])

  // initialLoad
  useEffect(() => {
    const getHeaderSection = document.querySelector('.headerContent')
    getHeaderSection.classList.add('active')
  }, [])
  


  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, styles.headerContent, 'section', 'headerContent')}>
        <h1 className={styles.header}>karynn tran</h1>
        <h5>I'm a software engineer based out of New York, focused on the front end and user experience.</h5>
      </div>
      <div 
        className={clsx(styles.section, styles.aboutSection, 'section')}
        // ref={containerRef}
      >
        <div className={clsx(styles.content, styles.aboutContent)}>
          <h4 className={styles.header}>about me </h4>
          <p>
            I'm a software engineer today, but before writing code, I used to write stories.
          </p>
          <p>
          My career began in consumer research as a qualitative storyteller: I took hundreds of journeys through the lives of consumers and surfaced fascinating insights about their relationships with brands.
          </p>
          <p>
            Today, as an engineer, I strive to keep the user front and center, building engaging digital experiences through beautiful design, clean code, empathy and thoughtful user experience.
          </p>
          <div className={styles.accent}>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
          </div>
        </div>
      </div>
      <div 
        className={clsx(styles.section, styles.workSection, 'section')}
        // ref={containerRef}
      >
        <div className={clsx(styles.workContent, styles.content)}>
          <h4 className={styles.header}>where i've worked</h4>
          <p>
            village md
          </p>
          <h6>senior software engineer</h6>
          <p>
            techstars
          </p>
          <h6>software engineer</h6>
          <p>
            remind
          </p>
          <h6>freelance software engineer</h6>
          <p>
            quartz
          </p>
          <h6>software engineer</h6>
          <p>
            td ameritrade
          </p>
          <h6>web developer apprentice</h6>
          <p>
            cspace
          </p>
          <h6>senior consultant</h6>
          <div className={styles.accent}>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.section, styles.skillsSection, 'section')}>
        <div className={clsx(styles.content, styles.skillsContent)}>
          <h4 className={styles.header}>technologies / skills</h4>
          <h6>code</h6>
          <p>
            javascript, css, react, typescript, node, express, postgres, sql, graphql, elasticsearch
          </p>
          <h6>design </h6>
          <p>
            sass, material-ui, adobe creative suite, figma, sketch, storybook
          </p>
          <h6>testing</h6>
          <p>
            jest, react testing library, selenium, nightwatch.js
          </p>
          <h6>other</h6>
          <p>
            contentful, gatsby, strapi, user research, qualitative data
          </p>
          <div className={styles.accent}>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.section, styles.contactSection, 'section')}>
        <div className={clsx(styles.content,styles.contactContent)}>
          <h4 className={styles.header}>contact</h4>
          <div className={styles.contactLinks}>
            <a className={styles.contactLink} href="mailto:karynn.tran@gmail.com">email</a> 
            &nbsp;|&nbsp;
            <a className={styles.contactLink} href="https://www.linkedin.com/in/karynneliotran/" target="_blank">linkedin</a>
            &nbsp;|&nbsp;
            <a className={styles.contactLink} href="https://github.com/karynntran" target="_blank">github</a>
          </div>
          <div className={styles.accent}>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
            <div className={styles.accentShape}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App